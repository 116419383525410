import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["body"];

  connect() {
    this.numberOfSlides = JSON.parse(this.element.dataset.carouselSlides);
    this.addEventListeners();
    this.recalculateHeight();
    this.initializeCarousel();
  }

  addEventListeners() {
    document.addEventListener("open.hs.overlay", this.handleOverlayOpen.bind(this));

    const fileUploadInput = this.element.closest(".file-upload-input");
    fileUploadInput?.addEventListener("carousel:ImagesAdded", this.handleImagesAdded.bind(this));
    this.element.addEventListener("carousel:ImageRemoved", this.handleImagesRemoved.bind(this));
  }

  goToPrev() {
    const { currentIndex, slides } = this.carouselInstance;
    this.carouselInstance.currentIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    this.setIndex();
  }

  goToNext() {
    const { currentIndex, slides } = this.carouselInstance;
    const maxIndex = slides.length - 1;
    this.carouselInstance.currentIndex = currentIndex >= maxIndex ? 0 : currentIndex + 1;
    this.setIndex();
  }

  goTo(index) {
    this.carouselInstance.currentIndex = index;
    this.setIndex();
  }

  handleOverlayOpen() {
    this.recalculateHeight();
    if (this.carouselInstance) {
      this.carouselInstance.recalculateWidth();
    }
  }

  initializeCarousel() {
    const interval = 2000;
    const maxAttempts = 20;

    let attempts = 0;

    const checkCarouselInstance = () => {
      if (!window.$hsCarouselCollection) {
        window.$hsCarouselCollection = [];
      }

      const carouselInstance = window.HSCarousel.getInstance(this.element);

      if (carouselInstance) {
        this.carouselInstance = carouselInstance;
        this.setIndex();
      } else if (attempts < maxAttempts) {
        attempts++;
        setTimeout(checkCarouselInstance, interval);
      } else {
        console.error("No carousel after 20 attempts.");
      }
    };

    checkCarouselInstance();
  }

  setIndex() {
    this.bodyTarget.dataset.index = this.carouselInstance.currentIndex;
  }

  handleImagesRemoved(event) {
    const { id } = event.detail;

    const filteredSlides = Array.from(this.getSlides()).filter((slide) => {
      return slide.querySelector("img").id !== id;
    });

    const images = filteredSlides.map((slide) => {
      return {
        id: slide.querySelector("img").id,
        src: slide.querySelector("img").src,
      };
    });

    if (images.length === 0) {
      this.element.classList.add("hidden");
    } else {
      this.updateSlides(images);
      this.carouselInstance.currentIndex;
      this.setIndex();
    }
  }

  handleImagesAdded(event) {
    const { files } = event.detail;
    const images = Array.from(files).map((file) => {
      return {
        id: file.name,
        src: URL.createObjectURL(file),
      };
    });

    this.updateSlides(images);
    this.carouselInstance.currentIndex = 0;
    this.setIndex();
  }

  updateSlides(images) {
    const pagination = this.element.querySelector(".hs-carousel-pagination");
    console.log(pagination);
    const prevButton = this.element.querySelector(".hs-carousel-prev");
    const nextButton = this.element.querySelector(".hs-carousel-next");

    if (this.carouselInstance) {
      this.carouselInstance.goTo(0);

      const slides = images
        .map(
          (image) => `
        <div class="hs-carousel-slide" style="width: ${this.carouselInstance.sliderWidth}px;">
          <div class="flex justify-center h-full bg-gray-100 bg-gradient-to-t from-chalk-900 to-chalk-700">
            <img src="${image.src}" id="${
            image.id || ""
          }" class="object-contain w-full h-full rounded-lg" />
          </div>
        </div>
      `
        )
        .join("");

      this.setSlides(slides);
      this.setActiveSlide(this.carouselInstance.currentIndex);
    }

    if (pagination) {
      const dots = images
        .map(
          () => `
        <span class="hs-carousel-active:bg-white bg-white/40 size-3 rounded-full cursor-pointer"></span>
      `
        )
        .join("");

      this.setDots(dots);
      this.setActiveDot(this.carouselInstance.currentIndex);

      if (images.length > 1) {
        pagination.classList.remove("hidden");
        prevButton.classList.remove("hidden");
        nextButton.classList.remove("hidden");
      } else {
        pagination.classList.add("hidden");
        prevButton.classList.add("hidden");
        nextButton.classList.add("hidden");
      }
    }

    if (this.carouselInstance) {
      this.carouselInstance.recalculateWidth();
    }
    this.recalculateHeight();
  }

  getSlides() {
    return this.carouselInstance.slides;
  }

  getDots() {
    return this.carouselInstance.dots;
  }

  setDots(dots) {
    this.carouselInstance.el.querySelector(".hs-carousel-pagination").innerHTML = dots;
    this.carouselInstance.dots = [
      ...this.carouselInstance.el.querySelectorAll(".hs-carousel-pagination *"),
    ];
  }

  setSlides(slides) {
    this.carouselInstance.inner.innerHTML = slides;
    this.carouselInstance.slides = [...this.carouselInstance.inner.children];
    this.carouselInstance.inner.style.width = `${
      this.carouselInstance.slides.length * this.carouselInstance.sliderWidth
    }px`;
  }

  setActiveSlide(index) {
    const slides = this.getSlides();

    Array.from(slides).forEach((el, i) => {
      if (i === index) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  }

  setActiveDot(index) {
    const dots = this.element.querySelectorAll(".dot");

    Array.from(dots).forEach((el, i) => {
      if (i === index) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  }

  recalculateHeight() {
    const { element } = this;
    const body = element.querySelector(".hs-carousel-body");
    const parent = element.parentElement;
    const carouselHeight = parent.clientHeight;

    element.style.height = `${carouselHeight}px`;
    body.style.height = `${carouselHeight}px`;
  }
}
