/* global Stimulus */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  uploadData(endpoint, data) {
    return fetch(endpoint, {
      method: "POST",
      body: data,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  submitSignature() {
    const formData = new FormData();
    this.getSignaturePad()
      .getSignatureData()
      .then(this.blobToBase64)
      .then((base64String) => {
        formData.append("pet_consent_form[signature_blob]", base64String);

        this.uploadData("/mygoodbye/home/consent_form/" + this.data.get("serviceidValue"), formData)
          .then(() => {
            const url = new URL(window.location);
            url.searchParams.set("modal-consent_form_success", "true");
            window.location.href = url.toString();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  }

  getSignaturePad() {
    const signaturePadElement = document.querySelector("[data-controller='signature_pad']");
    if (signaturePadElement) {
      const signaturePadController = Stimulus.getControllerForElementAndIdentifier(
        signaturePadElement,
        "signature_pad"
      );

      if (signaturePadController) {
        return signaturePadController;
      }
    }
  }
}
