import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["track", "item", "prevButton", "nextButton"];
  static values = {
    itemsPerView: Number,
    totalItems: Number,
  };

  initialize() {
    this.currentIndex = 0;
    this.itemsMinWidth = this.itemTargets[0].firstElementChild
      .computedStyleMap()
      .get("min-width").value;
  }

  connect() {
    this.updateItemsPerView();
    this.refreshControls();
    window.addEventListener("resize", this.updateItemsPerView.bind(this));
  }

  next() {
    const maxIndex = this.totalItemsValue - this.itemsPerViewValue;
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.moveTrack();
    }
  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.moveTrack();
    }
  }

  moveTrack() {
    const shiftPerItem = 100 / this.itemsPerViewValue;
    const shiftPercentage = -(this.currentIndex * shiftPerItem);
    this.trackTarget.style.transform = `translateX(${shiftPercentage}%)`;
    this.refreshControls();
  }

  updateItemsPerView() {
    const containerWidth = this.element.offsetWidth;

    let newItemsPerView = Math.floor(containerWidth / this.itemsMinWidth);

    if (newItemsPerView < 1) {
      newItemsPerView = 1;
    }

    this.itemsPerViewValue = Math.min(newItemsPerView, this.totalItemsValue);
    this.itemTargets.forEach((item) => {
      item.style.width = `${100 / this.itemsPerViewValue}%`;
    });

    const maxIndex = this.totalItemsValue - this.itemsPerViewValue;
    if (this.currentIndex > maxIndex) {
      this.currentIndex = maxIndex < 0 ? 0 : maxIndex;
    }

    this.moveTrack();
  }

  refreshControls() {
    const maxIndex = this.totalItemsValue - this.itemsPerViewValue;

    if (this.currentIndex <= 0) {
      this.prevButtonTarget.disabled = true;
    } else {
      this.prevButtonTarget.disabled = false;
    }

    if (this.currentIndex >= maxIndex) {
      this.nextButtonTarget.disabled = true;
    } else {
      this.nextButtonTarget.disabled = false;
    }
  }
}
