import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "guestbook"];
  connect() {
    const isSidebarEmpty = this.sidebarTarget.innerHTML.trim() === "";
    if (isSidebarEmpty) {
      this.guestbookTarget.classList.replace("col-span-2", "col-span-3");
    }
  }
}
