import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async connect() {
    if (this.element.dataset.openOnLoad === "true") {
      const urlParams = new URLSearchParams(window.location.search);
      const openModalParam = urlParams.get(this.element.id);

      if (openModalParam === "true") {
        await this.openModal(this.element.id);
      }
    }
  }

  async openModal(selector) {
    await this.waitForHSOverlay();
    const modalInstance = window.HSOverlay.getInstance(`#${selector}`);
    if (modalInstance) {
      window.HSOverlay.open(`#${selector}`);
      this.clearUrlParam(selector);
    }
  }

  clearUrlParam(param) {
    const url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, document.title, url);
  }

  waitForHSOverlay() {
    const maxAttempts = 20;

    return new Promise((resolve, reject) => {
      let attempts = 0;
      const interval = setInterval(() => {
        if (window.HSOverlay && window.HSOverlay.getInstance) {
          clearInterval(interval);
          resolve();
        } else if (attempts >= maxAttempts) {
          clearInterval(interval);
          reject("HSOverlay failed to load.");
        }
        attempts++;
      }, 2000);
    });
  }
}
